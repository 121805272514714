<template>
  <div>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            placeholder="Search for products"
            outlined
            hide-details
            single-line
            dense
            v-model="search"
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="(prod, index) in prods" :key="index">
        <v-list-item-content>
          <v-list-item-title>
            <v-chip class="mr-2">{{ prod.skuNumber }}</v-chip
            >{{ prod.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            color="primary"
            small
            @click="selectProduct(prod)"
            v-if="!isSelected(prod)"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>Add Link</v-btn
          >
          <v-btn v-else color="success" small @click="deselectProduct(prod)">
            <v-icon class="mr-2"> mdi-minus </v-icon>
            Remove Link
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-pagination :length="totalPages" v-model="pageNumber">
          </v-pagination>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      default: "",
    },
    productId: {
      type: String,
      default: "",
    },
    defaultSelectedProducts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalPages() {
      if (this.totalProdCount > 0) {
        return Math.ceil(this.totalProdCount / this.pageSize);
      } else {
        return 0;
      }
    },
  },
  created() {
    this.getProds();
  },
  watch: {
    defaultSelectedProducts: {
      immediate: true,
      handler(){
        if(!this.hasLoadedOnce){
          this.hasLoadedOnce = true;

          this.getProductLinkedProducts();
        }
      }
    },
    search(newValue, oldValue) {
      clearTimeout(this.searchDebounceId);

      this.searchDebounceId = setTimeout(() => {
        this.pageNumber = 1;
        this.getProds();
      }, 200);
    },
    pageNumber(newValue, oldValue) {
      this.getProds();
    },
  },
  methods: {
    getProductLinkedProducts() {
      for (const linkedProd of this.defaultSelectedProducts) {
        if (!this.isSelected(linkedProd)) {
          this.selectProduct(linkedProd);
        }
      }
    },
    isSelected(prod) {
      return this.selectedProducts.some((x) => x == prod.id);
    },
    getProds() {
      this.$courier.Companies.withId(this.companyId)
        .Products.getData(this.pageNumber, this.pageSize, this.search)
        .then((prodResp) => {
          this.prods = prodResp.payload;
          if(this.prods && this.prods.length > 0){
            this.prods = this.prods.filter(x => x.id !== this.productId);
          }
          this.totalProdCount = prodResp.totalCount;
        });
    },
    selectProduct(prod) {
      this.selectedProducts.push(prod.id);
      this.$emit('update:defaultSelectedProducts', this.selectedProducts);
    },
    deselectProduct(prod) {
      this.selectedProducts = this.selectedProducts.filter((x) => x != prod.id);
      this.$emit('update:defaultSelectedProducts', this.selectedProducts);
    },
  },
  data() {
    return {
      hasLoadedOnce: false,
      isLoading: false,
      searchDebounceId: "",
      prods: [],
      pageNumber: 1,
      pageSize: 10,
      search: "",
      totalProdCount: 0,
      selectedProducts: [],
    };
  },
};
</script>

<style>
</style>