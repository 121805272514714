<template>
  <div>
    
  </div>
</template>

<script>
import TrevXeroAccountCodeSearch from "../../../../components/forms/trev-xero-account-code-search.vue";
import TrevXeroTenantAccountCodeSearch from "../../../../components/forms/trev-xero-tenant-account-code-search.vue";
import TrevProductMetadata from "../../../../components/products/forms/trev-product-metadata.vue";
import TrevLinkedProductsManage from "../../../../components/products/trev-linked-products-manage.vue";

export default {
  components: {
    TrevProductMetadata,
    TrevXeroTenantAccountCodeSearch,
    TrevXeroAccountCodeSearch,

    TrevLinkedProductsManage,
  },
  mounted() {
    this.$courier.XeroAccountExternal.getData(1, 500, '', null, null, [{key: 'tenantId', value: 'c11d6023-8cfb-4376-9ae3-d0b077b41888'}]).then(resp => {
      console.log(resp.payload);
    })
  },
  methods: {},
  data() {
    return {
      prod: {},
      tagz: [],
      tenantId: "c11d6023-8cfb-4376-9ae3-d0b077b41888",
      anal: "231",
    };
  },
};
</script>

<style>

</style>